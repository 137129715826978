import { findQuestions } from '.';
import { Condition, IQuestion } from '../../interfaces/Question';
import { decryptPayload, encryptPayload } from '../../services/saveResponse';
import { httpRequest } from '../../services/network';
import { InboxUser } from '../../interfaces/inboxUser';

export const getIfElseNextQuestion = async (
  questions: Array<IQuestion>,
  ifElseComponent: IQuestion,
  userMessage: string,
  finalQuestions: Array<IQuestion>,
  uuid: string
): Promise<Array<IQuestion>> => {

  try {  
    const inboxUser: InboxUser = await getInboxUser(uuid);  
    
    for (const branch of (ifElseComponent.ifElse?.branches || [])) {
      let branchIsTrue = false;

      for (const orGroup of (branch.if.orGroups || [])) {

        const isAndConditionTrue = await Promise.all(
          orGroup.conditions.map(async condition => {
            try {

              /**
               * If Else component has two parts:
               * 1. User Message, User chat properties, User custom attributes, User contact properties: (evaluateCondition)
               * 2. Value to match it against with: (compareWith)
               */
              const compareWith = getValueToCheck(inboxUser, condition, userMessage);
              const subscriberValue = await getSubscriberValueToCheck(inboxUser, condition, userMessage);
              const result = evaluateCondition(compareWith, condition.operator, subscriberValue as string);              

              return result;
            } catch (error) {
              return false;
            }
          })
        )
          .then(results => results.every(result => result));

        if (isAndConditionTrue) {
          branchIsTrue = true;
          break;
        }

      }

      if (branchIsTrue) {
        return branch.if.target
          ? [
            ...finalQuestions,
            ...await findQuestions(questions, questions.findIndex(que => que.id === branch.if.target))
          ]
          : finalQuestions;
      }
    }

    return ifElseComponent.next.target
      ? [
        ...finalQuestions,
        ...await findQuestions(questions, questions.findIndex(que => que.id === ifElseComponent.next.target))
      ]
      : finalQuestions;

  } catch (error) {
    return finalQuestions;
  }
};

const evaluateCondition = (compareWith: string, operator: string, subscriberValue: string | boolean | Array<string>): boolean => {
  try {

    if (Array.isArray(subscriberValue)) {
      return handleArrayFieldEvaluation(compareWith, operator, subscriberValue);
    }

    return handleEvaluation(compareWith, operator, subscriberValue as string);

  } catch (error) {
    return false;
  }
};

const handleEvaluation = (compareWith: string, operator: string, subscriberValue: string) => {
  const compareWithSanitized = String(compareWith)
    .trim()
    .toLowerCase();

  const subscriberValueSanitized = String(subscriberValue)
    .trim()
    .toLowerCase();

  switch (operator) {
    case 'equals':

      return compareWithSanitized === subscriberValueSanitized;
    case 'notEquals':

      return compareWithSanitized !== subscriberValueSanitized;
    case 'greaterThan':

      return parseFloat(subscriberValueSanitized) > parseFloat(compareWithSanitized);
    case 'lessThan':

      return parseFloat(subscriberValueSanitized) < parseFloat(compareWithSanitized);
    case 'isAnyOf':

      return compareWithSanitized.split(',')
        .map(v => v.trim())
        .includes(subscriberValueSanitized);
    case 'isNotAnyOf':

      return !compareWithSanitized.split(',')
        .map(v => v.trim())
        .includes(subscriberValueSanitized);
    case 'startsWith':

      return subscriberValueSanitized.startsWith(compareWithSanitized);
    case 'doesNotStartsWith':

      return !subscriberValueSanitized.startsWith(compareWithSanitized);
    case 'endsWith':

      return subscriberValueSanitized.endsWith(compareWithSanitized);
    case 'doesNotEndsWith':

      return !subscriberValueSanitized.endsWith(compareWithSanitized);
    case 'containsAllOf':

      return subscriberValueSanitized.includes(compareWithSanitized);
    case 'doesNotContainsAllOf':

      return !subscriberValueSanitized.includes(compareWithSanitized);
    case 'containsAnyPartOf':
      const parts = compareWithSanitized.split(/\s+/);
      const regexPattern = new RegExp(`(${compareWithSanitized}|${parts.join('|')})`, 'i');

      return regexPattern.test(subscriberValueSanitized);
    case 'doesNotContainsAnyPartOf':
      const parts2 = compareWithSanitized.split(/\s+/);
      const regexPattern2 = new RegExp(`(${compareWithSanitized}|${parts2.join('|')})`, 'i');

      return !regexPattern2.test(subscriberValueSanitized);

    default:

      return false;
  }
};

/**
 *
 * Only in case of chatProperties: tag and status
 */
const handleArrayFieldEvaluation = (compareWith: string, operator: string, subscriberValue: Array<string>) => {
  const compareWithSanitized = String(compareWith)
    .trim()
    .toLowerCase();

  const subscriberValuSanitized = subscriberValue.map(v => String(v)
    .trim()
    .toLowerCase()
  );

  switch (operator) {
    case 'equals':

      return subscriberValuSanitized.some(v => v === compareWithSanitized);
    case 'notEquals':

      return !subscriberValuSanitized.some(v => v === compareWithSanitized);
    case 'isAnyOf':
      const conditionMatch = compareWithSanitized.split(',')
        .map(v => v.trim());

      return subscriberValuSanitized.some(val => conditionMatch.includes(val));

    case 'isNotAnyOf':
      const conditionMatch2 = compareWithSanitized.split(',')
        .map(v => v.trim());

      return subscriberValuSanitized.every(val => !conditionMatch2.includes(val));

    default:

      return false;
  }
};

const getValueToCheck = (inboxUser: InboxUser, condition: Condition, userMessage: string) => {
  return replaceWithCustomAttributes(condition.value, inboxUser?.profile?.userDetails)
    .toLocaleLowerCase();
};

const getSubscriberValueToCheck = async (
  inboxUser: InboxUser,
  condition: Condition,
  userMessage: string
) => {

  switch (condition.field) {
    case 'visitorsResponse':
      return userMessage;

    case 'chatProperty':
      return getChatProperty(condition, inboxUser);

    case 'teamAvailability':
      const isTeamAvailable = await getTeamAvailability(condition._departments);

      return isTeamAvailable;

    case 'customAttribute':

      const userAttributeValue = (inboxUser?.profile?.userDetails?.attributes || [])
        ?.find((attr: any) => attr.key === condition.customAttribute);

      return userAttributeValue?.value.toLocaleLowerCase();

    case 'contactProperty':
      return getContactProperty(condition, inboxUser);

    default:
      return '';
  }
};

const getChatProperty = (condition: Condition, inboxUser: InboxUser) => {
  switch (condition.chatProperty) {

    case 'status':
      return inboxUser?.status || '';

    case 'tag':
      return inboxUser?.profile?.userDetails?.tags || [];

    case 'weekday':
      const date = new Date();
      const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

      return daysOfWeek[date.getDay()];

    default:
      return '';
  }
};

const getContactProperty = (condition: Condition, inboxUser: InboxUser) => {
  switch (condition.contactProperty) {

    case 'name':
      return inboxUser?.profile?.userDetails?.userProvidedName || inboxUser?.profile?.userDetails?.name || '';

    case 'phone':
      return inboxUser?.profile?.userDetails?.whatsAppNumber || '';

    case 'email':
      return inboxUser?.profile?.userDetails?.contact?.email || '';

    case 'device':
      return inboxUser?.profile?.device?.device || '';

    case 'url campaign':
      return inboxUser?.profile?.marketing?.campaign || '';

    case 'url medium':
      return inboxUser?.profile?.marketing?.medium || '';

    case 'url source':
      return inboxUser?.profile?.marketing?.source || '';

    default:
      return '';
  }
};

// TODO: subscriber interface
const replaceWithCustomAttributes = (payload: string, userDetails: any) => {
  const attributes = userDetails?.attributes || [];

  if (!payload) {
    return payload;
  }

  let text = payload;

  attributes.forEach((item: any) => {
    text = text
      .split(`{{${item.key}}}`)
      .join(item.value);
  });

  text = text
    .replace('{{name}}', userDetails?.userProvidedName || '')
    .replace('{{mobileNumber}}', getPhoneNumber(userDetails?.contact?.phone))
    .replace('{{phone}}', getPhoneNumber(userDetails?.contact?.phone))
    .replace('{{email}}', userDetails?.contact?.email || '')
    .replace(/{{(.*?)}}/g, '');

  return text;
};


const getPhoneNumber = (phone: any) => {

  if (phone?.number && phone?.number !== 'NA') {
    return `${phone?.prefix !== 'NA' ? phone.prefix : ''} ${phone.number}`;
  }

  return '';
};


export const getTeamAvailability = async (departments: Array<string>) => new Promise((resolve, reject) => {
  httpRequest('GET', `v2/messenger/inbox/2/team-availabilty?departments=${departments.toString()}`,)
    .then((response: any) => {
      resolve(!!response && !!response.data && !!response.data.isTeamAvailable);
    })
    .catch(() => reject(false));
})

export const getInboxUser = async (uuid: string) => new Promise<InboxUser>((resolve, reject) => {

  httpRequest('POST', 'v2/messenger/inbox/2/messenger/inbox-user',
    {
      d: encryptPayload(
        {
          uuid, projection: { profile: 1 }
        },
        process.env.REACT_APP_ENCRYPTION_KEY
      )
    }
  )
    .then((response: any) => {
      const data = decryptPayload(response.data, process.env.REACT_APP_ENCRYPTION_KEY);
      resolve(data);
    })
    .catch(err => reject(err));

});